<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Beheer mijn CV" subtitle="Beheer al je CV data" />
    
    <div class="bg-white shadow overflow-hidden sm:rounded-lg" v-if='content.user'>
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          My Information
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Personal details and intro
        </p>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              Full name
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{content.user.first_name}} {{content.user.last_name}}
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              Email address
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{content.user.email}}
            </dd>
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-medium text-gray-500">
              About
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              <router-link :to="'/cv/intro/'+content.user.ms_oid" class="group flex items-center text-sm text-orange-600 hover:text-orange-900 font-medium space-x-2.5">
                <RequestQuote32 class="w-4 m-0" />
                <span>
                  Edit
                </span>
              </router-link>
            </dd>
          </div>
          <div class="sm:col-span-2">  
            <div v-html="content.user.description"></div>
          </div>
          <div class="sm:col-span-1">
            <DynamicImage v-if="content.photos && content.photos.length" :source="content.photos[0].data" type='600x800x' class="rounded shadow-md" />
          </div>
          <div class="sm:col-span-3">
            <dt class="text-sm font-medium text-gray-500">
              CV's
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li v-for="resume in content.resumes" :key="resume.id" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div class="w-0 flex-1 flex items-center">
                    <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-2 flex-1 w-0 truncate">
                      <router-link :to="'/cv/resumes/'+content.user.ms_oid+'/'+resume.id+'/edit'" class="text-orange-600 underline">
                        {{resume.name}}
                      </router-link>
                    </span>
                    <span class="flex text-green-600">
                      <ViewFilled32 v-if="resume.published" class="text-green-600 h-6 w-6 mr-2" />
                      <ViewOffFilled32 v-if="!resume.published" class="text-green-600 h-6 w-6 mr-2" />
                      {{resume.published ? 'gepubliceerd':'niet gepubliceerd'}}
                    </span>
                    
                  </div>
                  <div class="ml-4 flex">
                    <a :href="domain_url+'/cv/'+ content.user.slug + '/'+ resume.hash" class="inline-flex items-center mx-1 px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" target="_blank">
                      <Link32 class="-ml-0.5 mr-2 h-4 w-4" /> HTML
                    </a>
                    <a :href="domain_url+'/cv/'+ content.user.slug + '/'+ resume.hash + '/download'" class="inline-flex items-center mx-1 px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" target="_blank">
                      <Document32 class="h-5 w-5 text-white mr-2" /> PDF
                    </a>
                    <a :href="domain_url+'/cv/'+ content.user.slug + '/'+ resume.hash + '/download.docx'" class="inline-flex items-center mx-1 px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" target="_blank">
                      <Document32 class="h-5 w-5 text-white mr-2" /> DOCX
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
            <router-link :to="'/cv/resumes/'+content.user.ms_oid" class="text-orange-600 underline">manage resumes</router-link>
          </div>
        </dl>  
      </div>

      <div class="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <dt class="text-sm font-medium text-gray-500">
              Projects
            </dt>
            <dd class="mt-1 text-sm text-gray-900 bg-white">
              <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li v-for="project in content.projects" :key="project.id" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div class="w-0 flex-1 flex items-center">
                    <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-2 flex-1 w-0 truncate">
                      {{project.title}}
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <router-link :to="'/cv/projects/'+project.id+'/edit'" class="font-medium text-orange-600 hover:text-orange-500">edit</router-link>
                  </div>
                </li>
              </ul>
            </dd>
            <router-link :to="'/cv/projects/'+content.user.ms_oid" class="text-orange-600 underline">manage projects</router-link>
          </div>

        </dl>
      </div>

      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <dt class="text-sm font-medium text-gray-500">
              Languages
            </dt>
            <dd class="mt-1 text-sm text-gray-900 bg-white">
              <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li v-for="language in content.languages" :key="language.id" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div class="w-0 flex-1 flex items-center">
                    <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-2 flex-1 w-0 truncate">
                      {{language.name}}
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <router-link :to="'/cv/languages/'+language.id+'/edit'" class="font-medium text-orange-600 hover:text-orange-500">edit</router-link>
                  </div>
                </li>
              </ul>
            </dd>
            <router-link :to="'/cv/languages/'+content.user.ms_oid" class="text-orange-600 underline">manage languages</router-link>
          </div>

        </dl>
      </div>
      
      <div class="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <dt class="text-sm font-medium text-gray-500">
              Skills
            </dt>
            <dd class="mt-1 text-sm text-gray-900 bg-white">
              <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li v-for="skill in content.skills" :key="skill.id" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div class="w-0 flex-1 flex items-center">
                    <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-2 flex-1 w-0 truncate">
                      {{skill.title}}
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <router-link :to="'/cv/skills/'+skill.id+'/edit'" class="font-medium text-orange-600 hover:text-orange-500">edit</router-link>
                  </div>
                </li>
              </ul>
            </dd>
            <router-link :to="'/cv/skills/'+content.user.ms_oid" class="text-orange-600 underline">manage skills</router-link>
          </div>

        </dl>
      </div>

      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <dt class="text-sm font-medium text-gray-500">
              Education
            </dt>
            <dd class="mt-1 text-sm text-gray-900 bg-white">
              <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li v-for="education in content.educations" :key="education.id" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div class="w-0 flex-1 flex items-center">
                    <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-2 flex-1 w-0 truncate">
                      {{education.title}}
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <router-link :to="'/cv/education/'+education.id+'/edit'" class="font-medium text-orange-600 hover:text-orange-500">edit</router-link>
                  </div>
                </li>
              </ul>
            </dd>
            <router-link :to="'/cv/education/'+content.user.ms_oid" class="text-orange-600 underline">manage education</router-link>
          </div>

        </dl>
      </div>

      <div class="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <dt class="text-sm font-medium text-gray-500">
              Training / Courses
            </dt>
            <dd class="mt-1 text-sm text-gray-900 bg-white">
              <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li v-for="training in content.trainings" :key="training.id" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div class="w-0 flex-1 flex items-center">
                    <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-2 flex-1 w-0 truncate">
                      {{training.title}}
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <router-link :to="'/cv/training/'+training.id+'/edit'" class="font-medium text-orange-600 hover:text-orange-500">edit</router-link>
                  </div>
                </li>
              </ul>
            </dd>
            <router-link :to="'/cv/training/'+content.user.ms_oid" class="text-orange-600 underline">manage trainings</router-link>
          </div>

        </dl>
      </div>

      <div class="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div class="sm:col-span-2">
            <dt class="text-sm font-medium text-gray-500">
              References
            </dt>
            <dd class="mt-1 text-sm text-gray-900 bg-white">
              <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li v-for="reference in content.references" :key="reference.id" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div class="w-0 flex-1 flex items-center">
                    <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                    </svg>
                    <span class="ml-2 flex-1 w-0 truncate">
                      {{reference.reference_name}}
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <router-link :to="'/cv/references/'+reference.id+'/edit'" class="font-medium text-orange-600 hover:text-orange-500">edit</router-link>
                  </div>
                </li>
              </ul>
            </dd>
            <router-link :to="'/cv/references/'+content.user.ms_oid" class="text-orange-600 underline">manage references</router-link>
          </div>

        </dl>
      </div>

    </div>

  </main>
</template>

<script>
import DynamicImage from "../../../components/DynamicImage.vue";
import CvService from '../../../services/admin/cv/service';
import { ViewFilled32, ViewOffFilled32, Link32, Document32, RequestQuote32 } from '@carbon/icons-vue';

export default {
  name: 'CvData',
  components: {
    DynamicImage,
    ViewFilled32,
    ViewOffFilled32,
    Link32,
    Document32,
    RequestQuote32
  },
  data() {
    return {
      content: {},
      domain_url: process.env.VUE_APP_DOMAIN_URL
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      CvService.getMyCvData(this.$route.params.user_id).then(
        response => {
          this.content = response.data;
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          if (error.response.status === 401)
            this.$router.push(`/auth`);
        }
      );
    }
  }
};
</script>
